import Axios from 'axios-observable';
import { retry, map, delay } from 'rxjs/operators';

const delayWrapper = (min=0, max=0) => {
    return delay(Math.random() * (max - min) + min);
};

class ApiService {
    getHomeFeed() {
        return Axios.get("/data/homefeed.json")
        .pipe(
            map(rsp => {
                return rsp.data.feed;
            }),
            retry(3),
            delayWrapper()
        );
    };
}

export default new ApiService();