import React, { useState, useEffect } from 'react';
import Column from 'react-columns';
import ApiService from '../../services/apiservice';

import './videos.css';

export function Videos() {

    const [feed, setFeed] = useState([]);

    useEffect(() => {
        ApiService.getHomeFeed().subscribe((feed) => {
            console.info(feed);
            setFeed(feed);
        });
    }, []);

    return (
        <>
            <div className="container">
                <div className="box">
                    <div className="box-header">
                        <h1>
                            Recent videos
                        </h1>
                    </div>
                    <div className="box-content">
                    <Column columns={3} gap="10px" rootStyles={{}}>
                        {
                            feed.map((f, i) => {
                                return <div key={i} className="video mb-4">
                                    <a href={f.link} target="_blank" rel="noopener noreferrer">
                                    <img src={f.img} alt="thumbnail" className="img-fluid" />
                                    </a>
                                </div>
                            })
                        }
                    </Column>
                    </div>
                </div>
            </div>
        </>
    )
}