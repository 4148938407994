import React from 'react';

import './about.css';

export function Home() {
    return (
        <>
            <div className="container">
                <div className="row quotes p-4">
                    <div className="col-md-4">
                        <blockquote className="blockquote text-center">
                            <p className="mb-0">"Sexiest piece of hunk around"</p>
                            <footer className="blockquote-footer">My girlfriend (allegedly)</footer>
                        </blockquote>
                    </div>
                    <div className="col-md-4">
                        <blockquote className="blockquote text-center">
                            <p className="mb-0">"Very handsome man"</p>
                            <footer className="blockquote-footer">My mother</footer>
                        </blockquote>
                    </div>
                    <div className="col-md-4">
                        <blockquote className="blockquote text-center">
                            <p className="mb-0">"One of the best CS:GO players"</p>
                            <footer className="blockquote-footer">No one</footer>
                        </blockquote>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-3">
                        <div className="box">
                            <div className="box-header">
                                <h1>
                                    <span className="badge badge-primary mr-1">
                                        <i class="fas fa-cog"></i> 
                                    </span>
                                    System specs
                                </h1>
                            </div>
                            <div className="box-content">
                                <ul className="specs">
                                    <li>
                                        <strong>CPU</strong> Intel Core i7-5820K
                                    </li>
                                    <li>
                                        <strong>Memory</strong> 32GB Quad-Channel DDR4-1044MHz
                                    </li>
                                    <li>
                                        <strong>Graphics</strong> Geforce GTX 1080 Founders edition 8GB
                                    </li>
                                    <li>
                                        <strong>Headphones</strong> Logitech G533 DTS 7.1 Surround
                                    </li>
                                    <li>
                                        <strong>H.O.T.A.S</strong> Saitek X56 Rhino
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="box">
                            <div className="box-header">
                                <h1>
                                    <span className="badge badge-primary mr-1">
                                        <i class="fas fa-history"></i>
                                    </span>
                                    Short bio
                                </h1>
                            </div>
                            <div className="box-content">
                                What remains unsaid after all these endorsements?
                                <br /><br />
                                Hi, my name is Dimitris. As you might have guessed I like to play games and have done so for a long time.
                                <br />
                                I quickly got into gaming back in the 90s. One of my favourite games was Dangerous Dave. I then moved towards action games like Doom, Quake and Half-Life.
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="img">
                                            <img className="img-fluid" src="/img/dd.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="img">
                                            <img className="img-fluid" src="/img/doom.jpg" />
                                        </div>                                        
                                    </div>
                                </div>

                                Nowadays I enjoy space simulation / exploration games such as Elite: Dangerous, Star Citizen and No Man's Sky but I still continue playing action games such as Battlefield, Call Of Duty and more recently Rust.

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="img">
                                            <img className="img-fluid" src="/img/sc.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="img">
                                            <img className="img-fluid" src="/img/rust.jpg" />
                                        </div>                                        
                                    </div>
                                    <div className="col-md-4">
                                        <div className="img">
                                            <img className="img-fluid" src="/img/ed.jpg" />
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}