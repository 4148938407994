import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {Header, Footer, Videos, Home} from './components';

function App() {
  return (
    <BrowserRouter>
      <Header></Header>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/videos" component={Videos} />
      </Switch>

      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
