import React from 'react';

import './header.css';
import { Link, useLocation } from 'react-router-dom';

export function Header() {

    const loc = useLocation();

    return (
        <section className="header">
            <div className="intro d-flex align-items-center justify-content-center">
                <h1>Jimmy Plays Games</h1>
            </div>
            <div className="nav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="menu-container d-flex">
                                <img className="me" src="/img/me.png" alt="Me!"/>

                                <ul className="menu flex-grow-1">
                                    <li className={loc.pathname === "/" ? 'active' : ''}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className={loc.pathname === "/videos" ? 'active' : ''}>
                                        <Link to="/videos">Videos</Link>
                                    </li>
                                    <li>
                                        <a href="https://dkordas.com" target="_blank" rel="noopener noreferrer">Visit my blog</a>
                                    </li>
                                </ul>

                                <div className="flex-shrink-1 d-flex align-items-center justify-content-center">
                                    <a className="btn btn-social btn-youtube btn-sm mr-1" href="https://www.youtube.com/channel/UCeoxZLNNWCMSH0We4hk5oOg" target="_new">
                                        <svg id="svg-youtube" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet"><path d="M97.911,24.557c-1.149-4.549-4.538-8.13-8.842-9.347C81.271,13,49.999,13,49.999,13s-31.271,0-39.07,2.21c-4.302,1.216-7.69,4.798-8.84,9.347C0,32.8,0,50.001,0,50.001s0,17.2,2.089,25.443c1.15,4.549,4.538,8.132,8.84,9.348C18.729,87,49.999,87,49.999,87s31.271,0,39.07-2.208c4.304-1.216,7.692-4.799,8.842-9.348C100,67.201,100,50.001,100,50.001S100,32.8,97.911,24.557z M39.999,65.858V34.144L65.98,50.001L39.999,65.858z"></path></svg>
                                        &nbsp;Youtube
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}